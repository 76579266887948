exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-index-js": () => import("./../../../src/pages/api/index.js" /* webpackChunkName: "component---src-pages-api-index-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quick-wins-js": () => import("./../../../src/pages/quick-wins.js" /* webpackChunkName: "component---src-pages-quick-wins-js" */),
  "component---src-pages-sdk-js": () => import("./../../../src/pages/sdk.js" /* webpackChunkName: "component---src-pages-sdk-js" */),
  "component---src-templates-doc-article-js": () => import("./../../../src/templates/doc-article.js" /* webpackChunkName: "component---src-templates-doc-article-js" */)
}

