module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-images","id":"5a3a1a4b-c117-5474-90a1-b270d4281353","name":"gatsby-remark-images","version":"6.5.2","modulePath":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":1500},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-prismjs","id":"69468e53-70d7-588f-a4e9-7f3340cb5f30","name":"gatsby-remark-prismjs","version":"6.5.0","modulePath":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.5.0","modulePath":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":1500},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Notificare Docs","short_name":"Notificare","start_url":"/","background_color":"#232C2A","theme_color":"#232C2A","display":"standalone","icon":"src/images/notificare-grey-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d566884ee3d91c3eb6feec2580bbbb5a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/joeloliveira/Development/WWW/Projects/notificare-docs/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"nl"},{"name":"pt"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content"},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{},"Mdx":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.notifica.re","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
